import React from "react";
import Container from "react-bootstrap/Container";
import { Jumbotron } from "./migration";
import { useEffect, useRef } from 'react'
import ReactGA from 'react-ga4';

const MainBody = React.forwardRef(
  ({ gradient, title, icons }, ref) => {

    const toBottomEndRef = useRef(null)

    const scrollToBottom = () => {
      toBottomEndRef.current?.scrollIntoView({ behavior: "instant" })
    }
  
    useEffect(() => {
      scrollToBottom()
    });

    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home"
    });

    return (
      <Jumbotron
        fluid
        id="home"
        style={{
          background: `linear-gradient(136deg,${gradient})`,
          backgroundSize: "100% 100%",
        }}
        className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <Container className="text-center">
          <img 
            src="https://avatars.githubusercontent.com/u/21283729?v=4" 
            alt={title} 
            className="rounded-circle"
            width="250px"
            height="250px" />
          <h2 ref={ref} style={{marginTop: "20px", fontSize: "1.86rem"}}>
            {title}
          </h2>
          <div className="lead typist">
            <p style={{marginBottom: "8px", marginTop: "-2px"}}>Ph.D. in Artificial Intelligence</p>
            <p style={{fontSize: "18px"}}>--//--</p>
            <p style={{marginBottom: "8px"}}>Computer Science Professor</p>
            <p style={{marginBottom: "8px"}}>Data Science Consultant</p>
            <p style={{marginBottom: "8px"}}>ML / AI Researcher</p>
          </div>
          <div className="p-4">
            {icons.map((icon, index) => (
              <a
                key={`social-icon-${index}`}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i className={`${icon.image} fa-3x socialicons`} />
              </a>
            ))}
          </div>
          <div ref={toBottomEndRef} />
        </Container>
      </Jumbotron>
    );
  }
);

export default MainBody;
