
// Main Body SECTION
const mainBody = {
  gradientColors: "#434343, #000000",
  firstName: "Ricardo",
  middleName: "",
  lastName: "Cardoso Pereira",
  icons: [
    {
      image: "fab fa-github",
      url: "https://github.com/ricardodcpereira",
    },
    {
      image: "fas fa-graduation-cap",
      url: "https://scholar.google.com/citations?user=mHupdO8AAAAJ",
    },
    {
      image: "fas fa-building-columns",
      url: "https://www.cisuc.uc.pt/en/people/ricardo-pereira",
    },
    {
      image: "fab fa-linkedin",
      url: "https://www.linkedin.com/in/ricardo-dc-pereira",
    },
    {
      image: "fas fa-envelope",
      url: "mailto:ricardo.dc.pereira@gmail.com",
    },
  ],
};

export { mainBody };
