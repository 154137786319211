import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { mainBody } from "./editable-stuff/config.js";
import MainBody from "./components/MainBody";
import ReactGA from 'react-ga4';

const Home = React.forwardRef((props, ref) => {
  return (
    <>
      <MainBody
        gradient={mainBody.gradientColors}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        message={mainBody.message}
        icons={mainBody.icons}
        ref={ref}
      />
    </>
  );
});

const App = () => {
  const titleRef = React.useRef();

  ReactGA.initialize('G-72KC13CCF9');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Home ref={titleRef} />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
